import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const ContactSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulContactPage {
        contactSection1 {
          id
          title
          content {
            content
          }
          url
          label
        }
        address
        phone
        hours {
          raw
        }
        map
      }
    }
  `)
  const { contactSection1, address, phone, hours, map } =
    data.contentfulContactPage
  return (
    <>
      <div className="contact-sec1">
        <div className="container">
          <div className="row">
            {contactSection1.map(item => (
              <div className="col col-md-4" key={item.id}>
                <div className="contact-sec1-box">
                  <div className="contact-sec1-box-inner">
                    <h4>{item.title}</h4>
                    <p>{item.content.content}</p>
                  </div>
                  <a href={item.url}>
                    <button type="button" className="d-btn">
                      {item.label}
                    </button>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="contact-sec2">
        <div className="container">
          <div className="row">
            <div className="col col-md-4 d-flex align-items-center contact-sec2-col">
              <div>
                <div className="contact-sec2-inner">
                  <h3>Address</h3>
                  <p className="d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.352"
                      height="23.136"
                      viewBox="0 0 17.352 23.136"
                    >
                      <path
                        id="address-icon"
                        d="M8.676,0A8.665,8.665,0,0,0,0,8.676c0,5.784,8.676,14.46,8.676,14.46s8.676-8.676,8.676-14.46A8.665,8.665,0,0,0,8.676,0Zm0,2.892A5.784,5.784,0,1,1,2.892,8.676,5.782,5.782,0,0,1,8.676,2.892Z"
                        fill="#ff0245"
                      />
                    </svg>
                    <span>{address}</span>
                  </p>
                </div>
                <div className="contact-sec2-inner hoursWrapper">
                  <h3>Phone & Hours</h3>
                  <p className="d-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.136"
                      height="23.136"
                      viewBox="0 0 23.136 23.136"
                    >
                      <path
                        id="phone-icon"
                        d="M28.278,22.494a14.662,14.662,0,0,1-4.589-.733,1.29,1.29,0,0,0-1.3.315L19.557,24.91a19.39,19.39,0,0,1-8.47-8.464l2.828-2.834a1.29,1.29,0,0,0,.315-1.3,14.7,14.7,0,0,1-.733-4.6,1.281,1.281,0,0,0-1.285-1.285h-4.5A1.281,1.281,0,0,0,6.427,7.712,21.849,21.849,0,0,0,28.278,29.563a1.281,1.281,0,0,0,1.285-1.285v-4.5a1.281,1.281,0,0,0-1.285-1.285Zm-1.285-4.5h2.571A11.569,11.569,0,0,0,17.995,6.427V9A9,9,0,0,1,26.992,17.995Zm-5.141,0h2.571a6.429,6.429,0,0,0-6.427-6.427v2.571A3.859,3.859,0,0,1,21.851,17.995Z"
                        transform="translate(-6.427 -6.427)"
                        fill="#ff0245"
                      />
                    </svg>
                    <span>{phone}</span>
                  </p>
                </div>
                <h5>Hours</h5>
                {renderRichText(hours)}
              </div>
            </div>
            <div className="col col-md-8 contact-sec2-img">
              <label>&nbsp;</label>
              <iframe
                width="705"
                height="475"
                style={{ border: 0 }}
                loading="lazy"
                allowFullScreen
                src={map}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactSection

import React, { useState } from "react"
import Select from "react-select"
import ReferralLinks from "../shared/referral_links"
import ReCAPTCHA from "react-google-recaptcha"

const customStyles = {
  control: (base, state) => ({
    ...base,
    // background: "transparent",
    border: "2px solid #fff",
    color: "#000",
    // match with the menu
    borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "#ff0245" : "#ff0245",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#ff0245" : "#ff0245",
      boxShadow: state.isFocused ? null : null,
      color: state.isFocused ? "brown" : "grey",
    },
  }),
  menu: (base, state) => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    border: "1px solid #ff0245",
    // kill the gap
    marginTop: 0,
  }),
  menuList: (base, state) => ({
    ...base,
    // kill the white space on first and last option
    padding: 0,
    color: "#262b4a",
    color: state.isFocused ? "orange" : "#ff0245",
  }),
}

const ReferralForm = ({ data }) => {
  const [capVal, setCapVal] = useState()
  const [values, setValues] = useState({
    page: "contact",
    fName: "",
    lName: "",
    phone: "",
    email: "",
    preferred: "",
    isPatient: "",
    message: "",
  })
  const [error, setError] = useState("")
  const [success, setSuccess] = useState(false)

  const { fName, lName, phone, email, preferred, isPatient, message } = values
  const [capErr, setCapErr] = useState("")
  const [fNameErr, setfNameErr] = useState("")
  const [lNameErr, setlNameErr] = useState("")
  const [phoneErr, setPhoneErr] = useState("")
  const [emailErr, setEmailErr] = useState("")
  const [preferredErr, setPreferredErr] = useState("")
  const [isPatientErr, setIsPatientErr] = useState("")
  const [messageErr, setMessageErr] = useState("")

  const preferredOptions = [
    { value: "email", label: "Email" },
    { value: "phone", label: "Phone" },
    { value: "anything", label: "Either is Fine" },
  ]

  const isPatientOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
    { value: "unsure", label: "Not Sure" },
  ]

  const handleChange = e =>
    setValues({ ...values, [e.target.name]: e.target.value })

  const onChangePreferred = selectedOption => {
    setValues({ ...values, preferred: selectedOption })
  }
  const onChangePatient = selectedOption => {
    setValues({ ...values, isPatient: selectedOption })
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(email)
  }

  const submitForm = async () => {
    setCapErr("")
    setfNameErr("")
    setlNameErr("")
    setPhoneErr("")
    setEmailErr("")
    setPreferredErr("")
    setIsPatientErr("")
    setMessageErr("")
    setSuccess(false)
    if (!capVal) {
      setCapErr("Are you a robot?")
    }
    if (!fName) {
      setfNameErr("First Name is required")
    }
    if (!lName) {
      setlNameErr("Last Name is required")
    }
    if (!phone) {
      setPhoneErr("Phone Number is required")
    }
    if (!email) {
      setEmailErr("Email is required")
    }
    if (!preferred.value) {
      setPreferredErr("Preferred Contact Method is required")
    }
    if (!isPatient.value) {
      setIsPatientErr("Patient Info is required")
    }
    if (!message) {
      setMessageErr("Message is required")
    }
    if (!validateEmail(email)) {
      setEmailErr("Please provide a valid email")
    }
    if (
      !capVal ||
      !fName ||
      !lName ||
      !phone ||
      !email ||
      !preferred.value ||
      !isPatient.value ||
      !message
    ) {
      return
    }
    try {
      const res = await fetch("/.netlify/functions/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
      if (res.ok) {
        setSuccess(true)
      }
    } catch (err) {
      console.log(err)
      setError("Something went wrong")
    }
  }

  return (
    <section className="referral-form-sec contact-form-sec">
      <div className="container">
        <div className="row">
          <div className="col contact-form-sec-top">
            <h2>Get In Touch</h2>
            <p>We do our best to respond to you as soon as we can.</p>
          </div>
          <div className="col">
            <div className="row">
              <div className="col col-md-6 referral-form-sec-inner">
                <label>
                  <input
                    type="text"
                    name="fName"
                    placeholder="First Name"
                    value={fName}
                    onChange={handleChange}
                  />
                  {fNameErr && <p>{fNameErr}</p>}
                </label>
                <label>
                  <input
                    type="text"
                    name="lName"
                    placeholder="Last Name"
                    value={lName}
                    onChange={handleChange}
                  />
                  {lNameErr && <p>{lNameErr}</p>}
                </label>
                <label>
                  <input
                    type="phone"
                    name="phone"
                    placeholder="Phone Number*"
                    value={phone}
                    onChange={handleChange}
                  />
                  {phoneErr && <p>{phoneErr}</p>}
                </label>
                <label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address*"
                    value={email}
                    onChange={handleChange}
                  />
                  {emailErr && <p>{emailErr}</p>}
                </label>
                <label>
                  <Select
                    options={preferredOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    required
                    styles={customStyles}
                    placeholder={`Preferred Contact Method*`}
                    name="preferred"
                    value={preferred}
                    onChange={onChangePreferred}
                  />
                  {preferredErr && <p>{preferredErr}</p>}
                </label>
                <label>
                  <Select
                    options={isPatientOptions}
                    className={`customDropDown`}
                    classNamePrefix={`customDropDown`}
                    styles={customStyles}
                    required
                    placeholder={`Is Your Pet Currently A Patient?*`}
                    name="isPatient"
                    value={isPatient}
                    onChange={onChangePatient}
                  />
                  {isPatientErr && <p>{isPatientErr}</p>}
                </label>
                <label>
                  <textarea
                    name="message"
                    placeholder="How Can We Help You?*"
                    value={message}
                    onChange={handleChange}
                  ></textarea>
                  {messageErr && <p>{messageErr}</p>}
                </label>
                <label>
                  <ReCAPTCHA
                    sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                    onChange={val => setCapVal(val)}
                  />
                  {capErr && <p>{capErr}</p>}
                </label>
                <div className="referral-link-btn text-center">
                  <button type="button" className="d-btn" onClick={submitForm}>
                    Submit
                  </button>
                  {error && <p>{error}</p>}
                  {success && (
                    <p>
                      Thank you for contacting us! We'll be in touch with you
                      soon.
                    </p>
                  )}
                </div>
              </div>
              <ReferralLinks data={data} />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ReferralForm
